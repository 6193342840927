<template>
<div>
    <invoice-aging-report-list></invoice-aging-report-list>
</div>
</template>

  
<script>
export default {}
</script>
